<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-05 09:36:04
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-02 17:29:43
-->
<template>
  <div class="main" v-show="supportLanguage.length > 1">
    <img :class="ismobile ? 'checked-icon-mobile' : 'checked-icon'" :src="checkItem.icon" @click="handleShowSelect" />
    <!-- 选择结果 -->
    <div class="selectResult" v-if="!ismobile" @click="handleShowSelect">
      <div class="selectResultcope">{{ checkItem.label }}</div>
      <img class="arrow-icon" src="../assets/select.png" :style="show ? 'transform: rotate(-180deg);' : 'transform: rotate(0deg);'" alt />
    </div>

    <div class="locale-select" v-show="show">
      <!--选择列表 -->
      <div
        v-for="item in options"
        class="locale-option"
        :class="{ active: checkVal == item.value }"
        :key="item.value"
        @click="handleChecked(item)"
        v-show="supportLanguage.includes(item.value)"
      >
        <div class="left">
          <img :src="item.icon" />
          <span>{{ item.label || item.value }}</span>
        </div>
        <img class="check" v-if="checkVal == item.value" src="../assets/duihao.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "../utils/tools";
export default {
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [
        {
          label: "简体中文",
          value: "zh_CN",
          icon: require("@/assets/country/zh_CN.png"),
        },
        {
          label: "English",
          value: "en_US",
          icon: require("@/assets/country/en_US.png"),
        },
      ],
    },
  },

  computed: {
    supportLanguage() {
      return this.$store.state.supportLanguage;
    },
    defaultLanguage() {
      return this.$store.state.defaultlanguage;
    },
  },
  watch: {
    value(val) {
      this.checkVal = val;
    },
    checkVal(val) {
      let result = this.options.filter((item) => item.value == val);
      if (result.length > 0) {
        this.checkItem = result[0];
      } else {
        this.checkItem = {};
      }
    },
  },
  data() {
    return {
      checkVal: this.value, // 选中的值
      checkItem: {}, // 选中的标
      ismobile: isMobile(),
      show: false,
      zhLanguageUrl: require("@/assets/duoyuyan.png"),
      enLanguageUrl: require("@/assets/duihao.png"),
    };
  },

  methods: {
    handleShowSelect() {
      this.show = !this.show;
    },
    handleChecked(item) {
      this.show = false;
      this.$emit("input", item.value);
      this.$store.commit("SetDefaultlanguage", item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 24px;
  display: flex;
  align-items: center;
}
img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.selectResult {
  display: flex;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: -0.2px;
  font-weight: 400;
  margin-left: 4px;
  line-height: 24px;
  position: relative;
  align-items: center;

  .arrow-icon {
    transition: all 0.5s;
    margin-left: 8px;
  }
}
.locale-select {
  padding: 12px 0;
  position: absolute;
  z-index: 1000000;
  width: 194px;
  top: 45px;
  right: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  animation: select 0.3s;

  .locale-option {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      font-size: 15px;
      img {
        margin-right: 10px;
      }
    }
  }

  @keyframes select {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.checked-icon-mobile {
  width: 0.6154rem;
  height: 0.6154rem;
  margin: 0;
}
</style>
