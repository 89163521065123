<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 14:23:37
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-02 17:15:07
-->
<template>
  <div class="footer">
    <div class="img">
      <img :src="globalConfig.usageLogo" alt />
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    globalConfig() {
      return this.$store.state.globalConfig;
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.footer {
  min-height: 88px;
  //   background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
}
</style>
