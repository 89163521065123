<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-17 18:17:13
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 20:55:39
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2022-12-09 14:30:41
-->
<template>
  <div class="header" :class="{ expand: !expand }">
    <div id="headerTop" class="head-top flex-center" :class="{ fixed: expand }">
      <div class="app-info flex-center" @click="handleToHome">
        <img class="app-logo" :src="globalConfig.usageLogo" alt />
        <span class="product-name text-ellipsis">{{ defaultlanDetail.productName }}</span>
        <span class="line">｜</span>
        <span class="app-name text-ellipsis">{{ defaultlanDetail.usageName }}</span>
      </div>
      <div class="app-action">
        <div class="search-bar flex-center" v-if="expand">
          <span class="search-icon">
            <svg-icon class="search-icon" @click="handleToSeach" :class="{ active }" iconClass="search-with-basis"></svg-icon>
          </span>
          <input
            v-model="searchValue"
            @focus="active = true"
            @focusout="active = false"
            class="search-input"
            maxlength="50"
            @keyup.enter="handleToSeach"
            :placeholder="$t('Header.SearhInput.placeholder')"
          />
        </div>
        <div class="locale" @click="Show">
          <localeSelectVue v-model="language" placeholder="请选择" />
        </div>
      </div>
    </div>
    <div class="app-detail" :class="{ hidden: expand }">
      <div class="app-title">{{ defaultlanDetail.productTitle }}</div>
      <div class="app-remark">{{ defaultlanDetail.productDescribe }}</div>
      <div class="search-bar flex-center">
        <span class="search-icon">
          <svg-icon class="search-icon" @click="handleToSeach" :class="{ active }" iconClass="search-with-basis"></svg-icon>
        </span>
        <input
          ref="keyboard_input"
          @keyup.enter="handleToSeach"
          class="search-input"
          maxlength="50"
          v-model="searchValue"
          @focus="active = true"
          @focusout="active = false"
          :placeholder="$t('Header.SearhInput.placeholder')"
        />
      </div>
      <div class="box"></div>
    </div>
  </div>
</template>
<script>
import localeSelectVue from "@/components/localeSelect.vue";
import { getDetailByDefaultLan } from "@/utils/tools";
export default {
  name: "Header",
  props: ["expand"],
  components: { localeSelectVue },
  data() {
    return {
      active: false,
      searchValue: "",
      language: "",
    };
  },
  computed: {
    defaultlanDetail() {
      return this.$store.state.defaultlanDetail;
    },
    globalConfig() {
      return this.$store.state.globalConfig;
    },
  },
  created() {
    this.searchValue = this.$route.query.searchValue;
  },
  mounted() {
    this.language = this.$store.state.defaultlanguage;
  },
  watch: {
    language(language) {
      const wikiDetail = getDetailByDefaultLan(language, this.globalConfig.usageManageDetails);
      this.$i18n.locale = language;
      this.$store.commit("SetDefaultlanDetail", wikiDetail);
    },
  },
  methods: {
    handleInput(e) {
      console.log(e.target.value);
    },
    handleActive() {
      this.active = true;
    },
    handleToHome() {
      if (this.$route.name != "Home") {
        this.$router.push({ name: "Home", params: this.$route.params });
      }
    },
    handleToSeach() {
      let timestamp = Math.random().toString();
      this.$router.push({
        name: "Search",
        params: this.$route.params,
        query: { searchValue: this.searchValue, timestamp },
      });
    },
    Show() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 72px;
  box-sizing: border-box;
  background-color: var(--theme-color);
  transition: all 1s ease-in-out;
  &.expand {
    height: 340px;
  }
  .locale {
    cursor: pointer;
    position: relative;
    margin-left: 29px;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-left: 14px;
    }
    .seleceImg {
      transform: rotate(0deg);
    }
    .localecope {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #ffffff;
      letter-spacing: -0.2px;
      font-weight: 400;
      margin-left: 4px;
    }
  }
  .head-top {
    // padding-top: 12px;
    padding-left: 40px;
    min-height: 72px;
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
    background-color: var(--theme-color);
    justify-content: space-between;
    &.fixed {
      position: fixed;
      z-index: 299;
    }
    .app-info {
      cursor: pointer;
      .app-logo {
        width: 42px;
        height: 42px;
        border-radius: 4px;
      }
      .product-name {
        font-size: 17px;
        color: #ffffff;
        letter-spacing: -0.23px;
        font-weight: 500;
        margin-left: 8px;
      }
      .line {
        color: #ffffff;
        margin: 3px;
        font-size: 17px;
      }
      .app-name {
        cursor: pointer;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: -0.2px;
        font-weight: 400;
      }
    }
    .app-action {
      display: flex;
      .search-bar {
        cursor: pointer;
        height: 40px;
        min-width: 220px;
        border-radius: 28px;
        border: 0.33px solid rgba(0, 0, 0, 0);
        box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.2);
        position: relative;
        margin: 0;
        .search-input {
          width: 100%;
          height: 100%;
          border: 0;
          text-indent: 50px;
          border-radius: 28px;
          background-color: transparent;
          font-size: 15px;
          letter-spacing: -0.23px;
          font-weight: 400;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(255, 255, 255, 0.8);
          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
          &:focus-visible {
            color: rgba(19, 18, 18, 0.8);
            outline: -webkit-focus-ring-color auto 0;
            background-color: #ffffff;
            &::placeholder {
              color: rgba(000, 000, 000, 0.4);
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
  .box {
    height: 0.1px;
  }

  .app-detail {
    padding: 0 40px;
  }

  .app-title {
    font-size: 36px;
    color: #ffffff;
    letter-spacing: -0.48px;
    text-align: center;
    font-weight: 600;
    margin-top: 48px;
  }

  .app-remark {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: -0.2px;
    text-align: center;
    font-weight: 400;
    margin-top: 12px;
  }
  .search-bar {
    cursor: pointer;
    height: 56px;
    max-width: 700px;
    margin: 32px auto;
    // padding: 0 20px;
    margin-bottom: 64px;
    border-radius: 28px;
    border: 0.33px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
    .search-input {
      width: 100%;
      height: 100%;
      border: 0;
      text-indent: 50px;
      border-radius: 28px;
      background-color: transparent;
      font-size: 17px;
      letter-spacing: -0.23px;
      font-weight: 400;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(255, 255, 255, 0.8);
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      &:focus-visible {
        color: rgba(19, 18, 18, 0.8);
        outline: -webkit-focus-ring-color auto 0;
        background-color: #ffffff;
        &::placeholder {
          color: rgba(000, 000, 000, 0.4);
        }
      }
    }

    .search-icon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 10px;
      fill: #fff;
      &.active {
        fill: rgba(000, 000, 000, 0.4);
      }
    }
    .plachord {
      font-size: 17px;
      color: rgba(255, 255, 255, 0.4);
      letter-spacing: -0.23px;
      font-weight: 400;
    }
  }
}
</style>
