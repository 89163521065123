<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-12-09 13:57:25
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-16 11:07:01
-->
<template>
  <div>
    <Header :expand="expand"></Header>
    <router-view :key="routerKey"></router-view>
    <Footer />
  </div>
</template>
<script>
import Header from "./module/Header.vue";
import Footer from "./module/Footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      expand: false,
      routerKey: "",
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(val) {
        if (val.name == "Home" || val.name == "Search") {
          this.expand = false;
        } else {
          this.expand = true;
        }
        this.routerKey = val.path;
      },
      immediate: true,
    },
  },
};
</script>
